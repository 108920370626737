import React from 'react';
import styled from 'styled-components';

// Styled components
const CleanList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

export default ({children}) => (<CleanList>{children}</CleanList>);