import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import CleanList from './../components/CleanList';

// Styled components
const ContactWrapper = styled.article`
	margin: auto;
	max-width: var(--line-length);
`;

export default () => (
	<Layout pageTitle="Contact Me" view="/contact">
		<ContactWrapper>
			<section>
				<h1>Contact Me</h1>
				<p>I can be reached most easily on my social media accounts, or if you'd like you can email me at <a className="link" href="mailto:brandon@ubben.co">brandon@ubben.co</a>.</p>
			</section>
			<section>
				<h2>Social Media Links</h2>
				<CleanList>
					<li><i className="fab fa-instagram fa-lg"></i>&nbsp;<a
					className="link" href="https://instagram.com/ubben.b" target="_blank" rel="noopener noreferrer"> Instagram</a></li>
					<li><i className="fab fa-codepen fa-lg"></i>&nbsp;<a className="link" href="https://codepen.io/ubbenco" target="_blank" rel="noopener noreferrer"> CodePen</a></li>
					<li><i className="fab fa-github fa-lg"></i>&nbsp;<a className="link" href="https://github.com/b-ubben" target="_blank" rel="noopener noreferrer"> Github</a></li>
					<li><i className="fab fa-linkedin fa-lg"></i>&nbsp;<a className="link" href="https://www.linkedin.com/in/brandonubben/" target="_blank" rel="noopener noreferrer"> LinkedIn</a></li>
					<li><i className="fab fa-facebook fa-lg"></i>&nbsp;<a className="link" href="https://www.facebook.com/ubbenco" target="_blank" rel="noopener noreferrer"> Facebook</a></li>

					<li><i className="fab fa-twitter fa-lg"></i>&nbsp;<a className="link" href="https://www.twitter.com/ubbenco" target="_blank" rel="noopener noreferrer">Twitter</a></li>
				</CleanList>
			</section>
		</ContactWrapper>
	</Layout>
);
